import React from 'react';

const Number = ({number}) => {
    return <div className="font-libre mr-2">
        <div className="min-w-5 min-h-7 bg-number-shadow bg-no-repeat bg-bottom bg-contain text-2xl">
            {number}
        </div>
    </div>
}

export default Number;
